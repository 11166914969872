@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MarkPro-Bold SKY';
  src: url('/public/fonts/MarkPro-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Book SKY';
  src: url('/public/fonts/MarkPro-Book.ttf') format('truetype');
}
@font-face {
  font-family: "MarkPro-Light SKY";
  src: url('/public/fonts/MarkPro-Light.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro-Light SKY";
  src: url('/public/fonts/MarkPro-Light.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro-Light SKY";
  src: url('/public/fonts/MarkPro-Light.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro-Light SKY";
  src: url('/public/fonts/MarkPro-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'MarkPro-Black SKY';
  src: url('/public/fonts/MarkPro-Black.ttf') format('truetype');
}
@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Bold.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

html{
  font-size:100%;
}

body {
  margin: 0;
  font-family: 'MarkPro-Book SKY',MarkPro-Light SKY, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev{
  z-index: 5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.md\:container {
  max-width: 1024px !important;
}
/* BB call support banner styles */

.fr-paragraph.fr-paragraph-text-size-s {
  font-size: 1.125rem;
}

.fr-paragraph.fr-paragraph-text-align-center {
  text-align: center;
}

.fr-paragraph.fr-paragraph-text-font-markprobold {
  font-family: 'MarkPro-Bold SKY';
}

.fr-paragraph.fr-paragraph-text-color-white {
  color: #fff;
}

.iframe-wrapper {
  width: 100%;
  aspect-ratio: 1023/575;
  overflow: hidden;
}
.promo-container {
  margin: 20px 60px 40px 40px;
  flex: 1;
}
@media (min-width: 1024px) {
  .promo-container {
    max-width: 1100px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .promo-container {
    max-width: 588px;
  }
}
@media (max-width: 767px) {
  .promo-container {
    max-width: 375px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

.tvc-bundle-carousel .slick-list {
  padding-top: 30px;
  overflow-x: hidden !important;
}
@media (min-width: 767px) {
  .tvc-bundle-carousel {
    padding-left: 30px;
  }
}

.tvc-bundle-carousel .slick-arrow.slick-prev:before,
.tvc-bundle-carousel .slick-arrow.slick-next:before {
  content: none;
}
.tvc-bundle-carousel .slick-disabled {
  opacity: 0.1;
}
.tvc-bundle-carousel .slick-dots li {
  width: 10px;
}
.tvc-bundle-carousel .slick-track .slick-slide {
  width: inherit !important;
}

.tvc-bundle-carousel .slick-dots li.slick-active button:before {
  color: #0057ff;
  font-size: 20px;
  width: 10px;
  height: 22px;
  opacity: 1;
}
/* for BB banner text */
.banner_font_bold {
  font-size: 18px;
  font-family: 'MarkPro-Black SKY';
  line-height: 1.2;
}
.w-325px {
  width: 325px;
}
.w-920px {
  width: 920px;
}
.w-800px {
  width: 800px;
}
.margin-120{
  margin-left: 40px;
  margin-right: 40px;
}
@media (min-width: 1024px) {
  .margin-120{
  margin-left: 120px;
  margin-right: 120px;
}
}

.top-96{
 top:24rem
}

.left-20{
  left:20rem
}

.sky-bg-daylight:hover{
  --bg-opacity: 1;
  background-color: #00013A;
  background-color: rgba(0, 1, 58, var(--bg-opacity));
}

.rolling-banner-carousel .custom-carousel .slick-dots {
  z-index: 1;
  bottom: 3.5rem;
}

